<template>
    <main class="mb-5">
        <section class="pages-path d-flex my-3 py-2 px-3 container bg-white box-shadow20 border-radius15 ">
            <svg class="ml-2" width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.15722 20.7714V17.7047C9.1572 16.9246 9.79312 16.2908 10.581 16.2856H13.4671C14.2587 16.2856 14.9005 16.9209 14.9005 17.7047V17.7047V20.7809C14.9003 21.4432 15.4343 21.9845 16.103 22H18.0271C19.9451 22 21.5 20.4607 21.5 18.5618V18.5618V9.83784C21.4898 9.09083 21.1355 8.38935 20.538 7.93303L13.9577 2.6853C12.8049 1.77157 11.1662 1.77157 10.0134 2.6853L3.46203 7.94256C2.86226 8.39702 2.50739 9.09967 2.5 9.84736V18.5618C2.5 20.4607 4.05488 22 5.97291 22H7.89696C8.58235 22 9.13797 21.4499 9.13797 20.7714V20.7714" stroke="#999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="ml-2 mt-1 bi bi-chevron-double-left" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" fill="#a5a5a5"/>
                <path fill-rule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" fill="#a5a5a5"/>
            </svg>
            <router-link tag="span" to="/" class="fontsize12 text-color-666">خانه</router-link>
            <svg class="mx-2 mt-1" width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.5 19L8.5 12L15.5 5" stroke="#a5a5a5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <router-link tag="span" to="/blogs" class="fontsize11 text-color-a5">لیست مطالب</router-link>
          <template v-if="category">
            <svg class="mx-2 mt-1" width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.5 19L8.5 12L15.5 5" stroke="#a5a5a5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span class="fontsize11 text-color-a5">دسته بندی {{ category.name }}</span>
          </template>
        </section>
        <hr class="w-100 container d-md-block d-none bg-color-fa"/>
        <div class="weblog-list-grid container text-right">
            <div class="welog-list-side d-md-block d-none">
                <CategoryList :selected-category="category"></CategoryList>
                <section class="advertisting1 bg-color-ea border-radius15 box-shaddow20 text-center mb-3">
                    <a href=""><img src="../../assets/images/ads.png" alt=""></a>
                </section>
                <PopularBlogs/>
            </div>
            <div class="weblog-list-main ">
                <section class="weblog-list-main-grid">
                  <Blog class="pb-3 ml-3 mb-4" v-for="(blog, index) in blogs.data" :key="index" :item="blog"></Blog>
                </section>
              <p class="text-info text-center" v-if="!blogs.data.length">مطلبی وجود ندارد</p>

              <section v-if="blogs.data" class="list-pagination text-center my-4 p-2 ">
                  <pagination :limit="2" :data="blogs" @pagination-change-page="loadItems" :disabled="disabled" class="justify-content-center">
                    <span slot="prev-nav" >
                      <svg class="page-arrow mb-2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="22" height="22"
                        viewBox="0 0 226 226" style=" fill:#000000;">
                        <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt"
                          stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0"
                          font-family="none" font-weight="none" font-size="none" text-anchor="none"
                          style="mix-blend-mode: normal">
                          <path d="M0,226v-226h226v226z" fill="none"></path>
                          <g fill="#b2b2b2">
                            <path
                              d="M82.80781,169.67656c1.05938,1.05937 2.47187,1.58906 3.70781,1.58906c1.23594,0 2.64844,-0.52969 3.70781,-1.58906l52.96875,-52.96875c2.11875,-2.11875 2.11875,-5.47344 0,-7.41563l-52.96875,-52.96875c-2.11875,-2.11875 -5.47344,-2.11875 -7.41563,0c-2.11875,2.11875 -2.11875,5.47344 0,7.41563l49.26094,49.26094l-49.26094,49.26094c-2.11875,2.11875 -2.11875,5.29688 0,7.41562z">
                            </path>
                          </g>
                        </g>
                      </svg>
                    </span>
                    <span slot="next-nav">
                      <svg class="page-arrow" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="22" height="22"
                        viewBox="0 0 226 226" style=" fill:#000000;">
                        <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt"
                          stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0"
                          font-family="none" font-weight="none" font-size="none" text-anchor="none"
                          style="mix-blend-mode: normal">
                          <path d="M0,226v-226h226v226z" fill="none"></path>
                          <g fill="#b2b2b2">
                            <path
                              d="M143.19219,56.32344c-2.11875,-2.11875 -5.47344,-2.11875 -7.41563,0l-52.96875,52.96875c-2.11875,2.11875 -2.11875,5.47344 0,7.41562l52.96875,52.96875c1.05937,1.05937 2.47188,1.58906 3.70781,1.58906c1.23594,0 2.64844,-0.52969 3.70781,-1.58906c2.11875,-2.11875 2.11875,-5.47344 0,-7.41563l-49.26094,-49.26094l49.26094,-49.26094c2.11875,-2.11875 2.11875,-5.29687 0,-7.41563z">
                            </path>
                          </g>
                        </g>
                      </svg>
                    </span>
                  </pagination>
                </section>
                <!-- <section class="d-flex flex-wrap justify-content-between mb-3 d-lg-none">
                    <button class="d-flex justify-content-center bg-color-ea p-sm-3 p-2 border-radius15 mb-2 w-49">                       
                        <span class="mr-2 fontsize11 weight-bold text-color-444">صفحه قبلی</span>
                    </button>  
                    <button class="d-flex justify-content-center bg-color-ea p-sm-3 p-2 border-radius15 mb-2 w-49">                    
                        <span class="mr-2 fontsize11 weight-bold text-color-444">  صفحه بعدی</span>
                    </button> 
                </section> -->
                
            </div>
        </div>

    </main>
</template>
<script>
import Blog from './component/blog/blog'
import CategoryList from "./component/blog/categoryList";
import PopularBlogs from "./component/blog/popularBlogs";

export default {
  name: 'blogs',
  components: {PopularBlogs, CategoryList, Blog},
  data() {
    return {
      blogs: {data: Array(6).fill(null)},
      categoryId: null,
      disabled: false
    }
  },
  mounted() {
    this.categoryId = this.$route.params.category;
    this.loadItems(1);
  },
  computed: {
      category() {
        if (!this.categoryId) {
          return null;
        }
        if (!this.$store.state.header.blogCategories) {
          return null;
        }
        let category = null;
        this.$store.state.header.blogCategories.forEach(item => {
          if (item && item.id == this.categoryId) {
            category = item;
          }
        })

        return category;
      }
  },
  methods: {
    loadItems(page = 1) {
      this.disabled = true
      let data = {page: page}
      if (this.categoryId) {
        data.category_id = this.categoryId;
      }
      this.$axios.get('/api/blogs', {
        params: data
      })
          .then((response) => {
            const data = response.data.data;
            this.blogs = data.blogs
          })
          .catch(function (response) {
            //handle error
          }).finally(() => {
            this.disabled = false  
      });
    }
  },
  metaInfo() {
    return {
      meta: [
        {property: 'og:title', content: 'مطالب وبلاگ'},
      ]
    }
  }
}
</script>